import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { buildConfigurationActivitiesUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ConfigurationList, ConfigurationActivities } from '../types';

export const loadConfigurationActivities = (
    filters: FilterObject[],
): Observable<{ items: ConfigurationList[] }> => {
    const obs = sendRetrieveRequest(buildConfigurationActivitiesUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ConfigurationActivities>) => {
            const configurationActivities = convertToCamel<ConfigurationList[]>(response.Activity);
            return observableOf({
                items: configurationActivities,
            });
        }),
    );
};
